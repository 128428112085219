import React, { useState } from "react";
import { TiTick } from "react-icons/ti";
import { createCase } from "../services/api-service";
import { useNavigate } from "react-router-dom";
import Toastify from "toastify-js";
import DatePicker from "react-datepicker"; // Import DatePicker component
import "react-datepicker/dist/react-datepicker.css"; // Import DatePicker styles
import { FaEye, FaEyeSlash } from "react-icons/fa";

const CreateCases = ({ addTask, closeModal, fetchTasks }) => {
  const [formData, setFormData] = useState({
    client_name: "",
    it_username: "",
    password: "",
    mobile_number: "",
    email_id: "",
    ca_name: "",
    ca_number: "",
    ca_email_id: "",
    case_status: "assessmentOrderReceived",
    case_description: "",
    pan: "",
    din: "",
    scrunity_by: "",
    under_section: "",
    due_date: null,
  });
  const [currentSection, setCurrentSection] = useState(0);
  const steps = ["Personal Details", "CA Details"];
  const [currentStep, setCurrentStep] = useState(1);
  const [complete, setComplete] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, due_date: date });
  };

  const validateSection = () => {
    const mandatoryFields = {
      0: ["client_name", "mobile_number"],
      1: ["case_description"],
    };

    return mandatoryFields[currentSection].every((field) => formData[field]);
  };

  const handleNext = () => {
    if (validateSection()) {
      if (currentStep === steps.length) {
        setComplete(true);
      } else {
        setCurrentStep((prev) => prev + 1);
        setCurrentSection((prev) => prev + 1);
      }
    } else {
      Toastify({
        text: "Please fill in all fields correctly.",
        duration: 3000,
        close: true,
        gravity: "top",
        position: "right",
        backgroundColor: "#f44336",
        stopOnFocus: true,
      }).showToast();
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateSection()) {
      Toastify({
        text: "Please fill in all fields correctly.",
        duration: 3000,
        close: true,
        gravity: "top",
        position: "right",
        backgroundColor: "#f44336",
        stopOnFocus: true,
      }).showToast();
      return;
    }
    try {
      const response = await createCase(formData);
      const newTask = {
        client_name: formData.client_name,
        it_username: formData.it_username,
        password: formData.password,
        mobile_number: formData.mobile_number,
        email_id: formData.email_id,
        ca_name: formData.ca_name,
        ca_number: formData.ca_number,
        ca_email_id: formData.ca_email_id,
        case_status: formData.case_status,
        case_description: formData.case_description,
        pan: formData.pan,
        din: formData.din,
        scrunity_by: formData.scrunity_by,
        under_section: formData.under_section,
        due_date: formData.due_date, // Include due_date in newTask
      };

      addTask(newTask);
      fetchTasks(); // Refresh tasks
      closeModal();
    } catch (error) {
      if (error.response?.data?.detail === "Token has expired") {
        console.error("Session expired, logging out...");
        localStorage.removeItem("access_token");

        Toastify({
          text: "Your session has expired. Please log in again.",
          duration: 3000,
          close: true,
          gravity: "top",
          position: "right",
          backgroundColor: "#f44336",
          stopOnFocus: true,
        }).showToast();

        navigate("/login");
      } else {
        Toastify({
          text: "An error occurred: " + error.message,
          duration: 3000,
          close: true,
          gravity: "top",
          position: "right",
          backgroundColor: "#f44336",
          stopOnFocus: true,
        }).showToast();

        console.error("An error occurred:", error.message);
      }
    }
  };

  return (
    <div className="login template d-flex justify-content-center align-items-center w-100">
      <div
        className="d-flex bg-light p-lg-2 p-md-2 p-sm-1 d-flex flex-column justify-content-between"
        style={{ borderRadius: "1.5rem", width: "75%", height: "90%" }}
      >
        <div className="d-flex flex-column gap-10 align-items-center justify-content-center">
          <div className="d-flex justify-content-between">
            {steps.map((step, i) => (
              <div
                key={i}
                className={`step-item ${currentStep === i + 1 && "active"} ${
                  (i + 1 < currentStep || complete) && "complete"
                }`}
              >
                <div className="step">
                  {i + 1 < currentStep || complete ? (
                    <TiTick size={24} />
                  ) : (
                    i + 1
                  )}
                </div>
                <p className="text-gray-500">{step}</p>
              </div>
            ))}
          </div>
        </div>
        {currentSection === 0 && (
          <div className="card personal-info-card shadow border-0 p-2 mb-5 bg-body rounded border-top-blue">
            <div className="card-body">
              <h3 className="card-title mb-4" style={{ fontSize: "1.2rem" }}>
                Personal Information
              </h3>
              <form className="card-content">
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <label
                      htmlFor="client_name"
                      className="form-label text-secondary fw-bold"
                    >
                      Client Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="client_name"
                      placeholder="Enter client name"
                      value={formData.client_name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label
                      htmlFor="it_username"
                      className="form-label text-secondary fw-bold"
                    >
                      IT Username
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="it_username"
                      placeholder="Enter IT username"
                      value={formData.it_username}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label
                      htmlFor="password"
                      className="form-label text-secondary fw-bold"
                    >
                      Password
                    </label>
                    <div
                      className="password-input-wrapper"
                      style={{ position: "relative" }}
                    >
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        id="password"
                        placeholder="Enter password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                      <span
                        onClick={togglePasswordVisibility}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      >
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <label
                      htmlFor="mobile_number"
                      className="form-label text-secondary fw-bold"
                    >
                      Mobile Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      id="mobile_number"
                      placeholder="Enter mobile number"
                      value={formData.mobile_number}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value)) {
                          handleChange(e);
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label
                      htmlFor="email_id"
                      className="form-label text-secondary fw-bold"
                    >
                      Email ID
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email_id"
                      placeholder="Enter email ID"
                      value={formData.email_id}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}

        {currentSection === 1 && (
          <div className="card personal-info-card shadow-sm p-2 mb-5 bg-body rounded border-top-blue">
            <div className="card-body">
              <h3 className="card-title mb-4" style={{ fontSize: "1.2rem" }}>
                Chartered Accountant Details
              </h3>
              <form className="card-content">
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <label
                      htmlFor="ca_name"
                      className="form-label text-secondary fw-bold"
                    >
                      CA Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="ca_name"
                      placeholder="Enter CA name"
                      value={formData.ca_name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label
                      htmlFor="ca_number"
                      className="form-label text-secondary fw-bold"
                    >
                      Mobile Number
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      id="ca_number"
                      placeholder="Enter mobile number"
                      value={formData.ca_number}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value)) {
                          handleChange(e);
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label
                      htmlFor="ca_email_id"
                      className="form-label text-secondary fw-bold"
                    >
                      Email ID
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="ca_email_id"
                      placeholder="Enter email ID"
                      value={formData.ca_email_id}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <label
                      htmlFor="pan"
                      className="form-label text-secondary fw-bold"
                    >
                      PAN
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="pan"
                      placeholder="Enter PAN"
                      value={formData.pan}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label
                      htmlFor="din"
                      className="form-label text-secondary fw-bold"
                    >
                      DIN
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="din"
                      placeholder="Enter DIN"
                      value={formData.din}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label
                      htmlFor="scrunity_by"
                      className="form-label text-secondary fw-bold"
                    >
                      Scrunity By
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="scrunity_by"
                      placeholder="Enter Scrunity By"
                      value={formData.scrunity_by}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  {/* <div className="col-md-4 mb-3">
                    <label
                      htmlFor="case_status"
                      className="form-label text-secondary fw-bold"
                    >
                      Case Status
                    </label>
                    <select
                      className="form-select"
                      id="case_status"
                      value={formData.case_status}
                      onChange={handleChange}
                    >
                      <option value="active">Active</option>
                      <option value="closed">Closed</option>
                    </select>
                  </div> */}
                  <div className="col-md-4 mb-3">
                    <label
                      htmlFor="under_section"
                      className="form-label text-secondary fw-bold"
                    >
                      Under Section
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="under_section"
                      placeholder="Enter Under Section"
                      value={formData.under_section}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label
                      htmlFor="due_date"
                      className="form-label text-secondary fw-bold"
                    >
                      Due Date
                    </label>
                    <DatePicker
                      selected={formData.due_date}
                      onChange={handleDateChange}
                      className="form-control"
                      placeholderText="Select due date"
                      dateFormat="MMMM d, yyyy"
                    />
                  </div>
                  <div className="col-md-8 mb-3">
                    <label
                      htmlFor="case_description"
                      className="form-label text-secondary fw-bold"
                    >
                      Case Description <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      id="case_description"
                      placeholder="Enter case description"
                      value={formData.case_description}
                      onChange={handleChange}
                      rows="1"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
        {/* {error && <p className="text-danger d-flex justify-content-end mb-1">{error}</p>} */}
        <div className="d-flex justify-content-end gap-2">
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => {
              if (currentSection === 0) {
                closeModal();
              } else {
                setCurrentSection(currentSection - 1);
                setCurrentStep(currentStep - 1);
              }
            }}
          >
            {currentSection === 0 ? "Close" : "Back"}
          </button>
          {currentStep < steps.length && (
            <button
              className="btn btn-primary"
              type="button"
              onClick={handleNext}
            >
              Next
            </button>
          )}
          {currentSection === steps.length - 1 && (
            <button
              className="btn btn-success"
              type="submit"
              onClick={handleSubmit}
            >
              Submit
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateCases;
