import React, { useMemo, useState } from "react";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import circle from "../assets/circle.svg";
import { CSS } from "@dnd-kit/utilities";
import TaskCard from "./TaskCard";

function ColumnContainer({
  column,
  updateColumn,
  tasks,
  updateTask,
  deleteTask
}) {
  const [editMode, setEditMode] = useState(false);

  const sortedTasks = useMemo(() => {
    return tasks.slice().sort((a, b) => {
      const dateA = new Date(a.due_date);
      const dateB = new Date(b.due_date);
      return dateA - dateB; // Ascending order
    });
  }, [tasks]);

  const tasksIds = useMemo(() => {
    return sortedTasks.map((task) => task.id);
  }, [sortedTasks]);

  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: column.id,
    data: {
      type: "Column",
      column,
    },
    disabled: true, // Disabling column dragging
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    height: '85vh',
    width: '18vw',
    borderRadius: '24px'
  };
  
  return (
    <div
      ref={setNodeRef}
      style={style}
      className="bg border d-flex flex-column p-2"
    >
      {/* Column title */}
      <div
        {...attributes}
        {...listeners}
        className="transparent bold text-md h-60 text-light cursor-grab font-bold border-4 d-flex align-items-center justify-content-between"
        style={{padding: '0.7rem', backgroundColor: "black"}}
      >
        <div className="d-flex gap-1 text-light" style={{fontWeight: '600'}}>
          <div className="d-flex justify-content-center align-items-center" style={{width: "7%"}}>
            <img src={circle} alt="" className="w-75" />
          </div>
          {!editMode && column.title}
          {editMode && (
            <input
              className="form-control"
              value={column.title}
              onChange={(e) => updateColumn(column.id, e.target.value)}
              autoFocus
              onBlur={() => setEditMode(false)}
              onKeyDown={(e) => {
                if (e.key !== "Enter") return;
                setEditMode(false);
              }}
            />
          )}
        </div>
        {/* <button
          onClick={() => deleteColumn(column.id)}
          className="btn btn-link p-0 text-danger"
        >
          <trash />
        </button> */}
      </div>

      {/* Column task container */}
      <div className="d-flex flex-grow-1 flex-column gap-2 p-2 overflow-auto">
        <SortableContext items={tasksIds}>
          {sortedTasks.map((task) => (
            <TaskCard
              key={task.id}
              task={task}
              updateTask={updateTask}
              deleteTask={deleteTask}
            />
          ))}
        </SortableContext>
      </div>
      {/* Column footer */}
      {/* <button
        className="btn d-flex align-items-center w-100"
        onClick={() => createTask(column.id)}
      >
        <div className="w-auto me-2">
          <img src={plus} alt="plusIcon" className="w-25 h-50" />
        </div>
        Add Items
      </button> */}
    </div>
  );
}

export default ColumnContainer;
