import React, { useState, useEffect } from "react";
import EditTaskModal from "./EditTaskModal"; // Ensure this path is correct
import trash from "../assets/trash.svg";
import { useSortable } from "@dnd-kit/sortable";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { getTodosByCaseId } from "../services/api-service";

function TaskCard({ task, updateTask, deleteTask }) {
  const [showModal, setShowModal] = useState(false);
  const [pendingTodos, setPendingTodos] = useState([]);
  const [isDraggingEnabled, setIsDraggingEnabled] = useState(true);

  const handleOpenModal = () => {
    console.log("Opening modal");
    setShowModal(true);
    setIsDraggingEnabled(false);
  };

  useEffect(() => {
    const fetchTodos = async () => {
      try {
        const response = await getTodosByCaseId(task.case_id); // Fetch todos
        const todos = response.data || []; // Access the data property and default to an empty array

        if (Array.isArray(todos)) { // Ensure todos is an array
          const pendingTodos = todos.filter(todo => !todo.done); // Filter pending todos
          setPendingTodos(pendingTodos.slice(0, 1)); // Set only the first pending todo
        } else {
          console.error("Fetched todos are not in expected array format:", todos);
        }
      } catch (error) {
        console.error("Error fetching todos:", error);
      }
    };

    fetchTodos();
  }, [task.case_id]);

  const handleCloseModal = (e) => {
    if (e) e.stopPropagation(); // Prevent the click event from propagating
    console.log("Closing modal");
    setShowModal(false);
    setIsDraggingEnabled(true);
  };

  const handleUpdateTask = (updatedTask) => {
    updateTask(updatedTask);
    setShowModal(false); // Close modal after updating task
  };

  const handleDeleteTask = async (caseId) => {
    try {
      await deleteTask(caseId);
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  // Safeguard for undefined content and handle default value
  const truncatedContent = (task.case_description || "").length > 100
      ? `${(task.case_description || "").substring(0, 100)}...`
    : (task.case_description || "");

  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: task._id,
    data: {
      type: "Task",
      task,
    },
    disabled: !isDraggingEnabled
  });

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    transition,
    boxShadow: isDragging ? "0 4px 8px rgba(0, 0, 0, 0.2)" : "0 4px 8px rgba(0, 0, 0, 0.1)",
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="task-card text-left cursor-pointer position-relative border-radius-1 fs-5"
      onClick={handleOpenModal}
    >
      <div
        className="client-name font-weight-bold d-flex"
        style={{
          borderTopRightRadius: "0.5rem",
          borderTopLeftRadius: "0.5rem",
        }}
      >
        <p className="mb-2">
          <strong>Client Name:</strong> {task.client_name || "No Name"}
        </p>
      </div>
      <div className="">
        <div className="case-details border-bottom border-top pt-2 pb-1 mb-2">
          <p className="mb-1">
            <strong>Case Details:</strong>{" "}
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{task.case_description || "No details available"}</Tooltip>}
            >
              <span>{truncatedContent}</span>
            </OverlayTrigger>
          </p>
        </div>

        <div className="border-bottom pb-1 mb-2">
          <p className="mb-1">
            <strong>Due Date:</strong>{" "}
            {task.due_date
              ? new Date(task.due_date).toLocaleDateString()
              : "N/A"}
          </p>
        </div>
        <div className="pending-todos">
          <strong>Pending Todos:</strong>
          {pendingTodos.length > 0 ? (
            <ul className="pl-3 mb-0">
              {pendingTodos.map((todo) => (
                <li key={todo.id} className="mb-1">
                  {todo.description || "No content"}
                </li>
              ))}
            </ul>
          ) : (
            <p className="mb-0">No pending todos</p>
          )}
        </div>

      </div>
      {/* Uncomment if delete button is needed */}
      {/* <button
        onClick={handleDeleteTask}
        className="btn btn-link position-absolute bg-transparent p-1 border-0"
        style={{ top: '2px', right: '0px' }}
      >
        <img src={trash} style={{ width: '12%' }} alt="trash icon" />
      </button> */}
      <EditTaskModal
        show={showModal}
        onHide={handleCloseModal}
        task={task}
        updateTask={handleUpdateTask}
        onDelete={() => handleDeleteTask(task.case_id)}
      />
    </div>
  );
}

export default TaskCard;
