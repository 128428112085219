import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Advocate from "../assets/adv_hand_img.png";
import { registerUser } from "../services/api-service.js";
import Loader from "./Loader.jsx";
import Toastify from "toastify-js";
import Logo from "../assets/VisumAI_Dark_Transparent.png";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import icons

const SignUp = () => {
  const [formData, setFormData] = useState({
    username: "",
    emailid: "",
    password: "",
    verifyPassword: "",
    user_type: "Select Role",
  });

  const [selectedRole, setSelectedRole] = useState("Select Role");
  const [showPassword, setShowPassword] = useState(false);
  const [showVerifyPassword, setShowVerifyPassword] = useState(false); // State for verify password
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRoleSelect = (user_type) => {
    setSelectedRole(user_type);
    if (user_type === "Senior Advocate") {
      user_type = "Admin";
    }
    setFormData({
      ...formData,
      user_type,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { username, emailid, password, verifyPassword, user_type } = formData;

    // Validate form data
    if (!username || !emailid || !password || user_type === "Select Role") {
      Toastify({
        text: "Please fill in all fields correctly.",
        duration: 3000,
        close: true,
        gravity: "top",
        position: "right",
        backgroundColor: "linear-gradient(to right, #FF6347, #FF4500)",
        stopOnFocus: true,
      }).showToast();
      return;
    }

    if (password !== verifyPassword) {
      Toastify({
        text: "Passwords do not match.",
        duration: 3000,
        close: true,
        gravity: "top",
        position: "right",
        backgroundColor: "linear-gradient(to right, #FF6347, #FF4500)",
        stopOnFocus: true,
      }).showToast();
      return;
    }

    setLoading(true);
    try {
      const response = await registerUser(formData);
      setSuccess(true);
      console.log("Registration successful:", response);
      Toastify({
        text: "Registration successful! Redirecting to login...",
        duration: 1000,
        close: true,
        gravity: "top",
        position: "right",
        backgroundColor: "linear-gradient(to right, #32CD32, #008000)",
        stopOnFocus: true,
      }).showToast();

      setTimeout(() => {
        navigate("/");
      }, 1000);
    } catch (error) {
      Toastify({
        text: "Sign up failed. Please try again.",
        duration: 3000,
        close: true,
        gravity: "top",
        position: "right",
        backgroundColor: "linear-gradient(to right, #FF6347, #FF4500)",
        stopOnFocus: true,
      }).showToast();
      console.error("Sign up failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleVerifyPasswordVisibility = () =>
    setShowVerifyPassword(!showVerifyPassword);

  return (
    <div
      className="login template d-flex justify-content-center align-items-center"
      style={{ backgroundColor: "var(--background-color)" }}
    >
      <div
        className="d-flex border overflow-hidden w-100 vh-100"
        style={{ width: "100%" }}
      >
        <div className="w-50 d-flex align-items-center justify-content-center">
          <img
            src={Advocate}
            className="m-auto"
            width={"450px"}
            alt="Advocate"
          />
        </div>
        <form
          className="w-50 p-lg-5 p-md-3 p-sm-2 bg-light d-flex align-items-center"
          style={{
            borderTopRightRadius: "1.5rem",
            borderBottomRightRadius: "1.5rem",
          }}
          onSubmit={handleSubmit}
        >
          <div className="w-100">
            <img
              src={Logo}
              className="d-block m-auto"
              width={300}
              alt="Advocate"
            />
            <h3
              className="d-flex mb-5 align-items-center justify-content-start font-montserrat fs-2"
              style={{ fontWeight: "700" }}
            >
              Sign Up
            </h3>

            <div className="mb-4">
              <input
                type="text"
                name="username"
                placeholder="Enter Name"
                className="lined-input font-bold"
                value={formData.username}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4">
              <input
                type="email"
                name="emailid"
                placeholder="Enter Email"
                className="lined-input font-bold"
                value={formData.emailid}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4 position-relative">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Password"
                className="lined-input"
                value={formData.password}
                onChange={handleChange}
                style={{ paddingRight: "40px" }} // Add padding for the icon
              />
              <span
                className="position-absolute"
                style={{
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEye /> : <FaEyeSlash /> }
              </span>
            </div>

            <div className="mb-4 position-relative">
              <input
                type={showVerifyPassword ? "text" : "password"}
                name="verifyPassword"
                placeholder="Verify Password"
                className="lined-input"
                value={formData.verifyPassword}
                onChange={handleChange}
                style={{ paddingRight: "40px" }} 
              />
              <span
                className="position-absolute"
                style={{
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={toggleVerifyPasswordVisibility}
              >
                {showVerifyPassword ? <FaEye /> : <FaEyeSlash />}
              </span>
            </div>

            <div className="mb-3">
              <label className="mb-1 font-bold" htmlFor="user_type">
                Select Role
              </label>
              <div className="dropdown">
                <button
                  className="btn btn-secondary dropdown-toggle form-control bg-white text-dark border"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {selectedRole}
                </button>
                <ul
                  className="dropdown-menu w-100"
                  aria-labelledby="dropdownMenuButton"
                >
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleRoleSelect("Advocate");
                      }}
                    >
                      Advocate
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleRoleSelect("Accountant");
                      }}
                    >
                      Accountant
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleRoleSelect("Senior Advocate");
                      }}
                    >
                      Senior Advocate
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="d-grid">
              {success && (
                <div className="alert text-success p-0 text-end mb-1">
                  Sign up successful!
                </div>
              )}
              <button className="btn text-primary text-light">Sign Up</button>
            </div>
            <div className="text-right d-flex justify-content-end mt-2">
              <p>
                Already have an account?{" "}
                <Link to="/" className="text-decoration-none">
                  Login
                </Link>
              </p>
            </div>
          </div>
        </form>
      </div>
      <Loader loading={loading} />
    </div>
  );
};

export default SignUp;
