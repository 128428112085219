import React, {useState} from "react";
import { FiClipboard, FiCheck } from "react-icons/fi";
import { Form, Col } from "react-bootstrap";

function CopyButton({ textToCopy }) {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
      navigator.clipboard.writeText(textToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Revert back to clipboard icon after 2 seconds
    };
  

  return (
    <button
    onClick={handleCopy}
    style={{
      background: "none",
      border: "none",
      cursor: "pointer",
      position: "absolute",
      right: "2px",
      top: "50%",
      transform: "translateY(-50%)",
    }}
  >
    {copied ? <FiCheck size={18} color="green" /> : <FiClipboard size={18} />}
  </button>
  );

}

export default CopyButton;
