// App.js
import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import CreateCases from './components/CreateCases';
import KanbanBoard from './components/KanbanBoard';
import HomePage from './components/HomePage';
import "toastify-js/src/toastify.css";
import 'react-datepicker/dist/react-datepicker.css';

function App() {

  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login/>}></Route>
          <Route path="/signup" element={<SignUp/>}></Route>
          <Route path="/homepage" element={<HomePage/>} />
          {/* <Route path="/create-cases" element={<CreateCases />} /> */}
          {/* <Route path="/kanban-board" element={<KanbanBoard/>} /> */}
        </Routes>
      </BrowserRouter>
  );
}

export default App;
