import React, { useState, useEffect } from 'react';
import { Card, ListGroup, Form, Spinner } from 'react-bootstrap';
import { getAllUsers, getUserDetails, updateUser } from '../services/api-service'; // Import API services

const UserDetails = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userStatus, setUserStatus] = useState({});
  const [currentUserRole, setCurrentUserRole] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersData = await getAllUsers();
        setUsers(usersData);

        // Initialize userStatus based on fetched users
        const statusObject = usersData.reduce((acc, user) => {
          acc[user.user_id] = user.Status === 1; // Assuming Status is 1 for active
          return acc;
        }, {});
        setUserStatus(statusObject);

        setLoading(false);
      } catch (error) {
        setError('Restricted Area || Admin Access Only');
        setLoading(false);
      }
    };

    const fetchUserRole = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const userDetails = await getUserDetails(token);
        setCurrentUserRole(userDetails.user_type);
        console.log(userDetails.user_type)
      } catch (error) {
        setError('Failed to fetch user details');
        setLoading(false);
      }
    };

    fetchUsers();
    fetchUserRole();
  }, []);

  const handleStatusChange = async (userId, username, email, usertype) => {
    console.log(userId, "user id")
    try {
      const newStatus = userStatus[userId] === 1 ? 0 : 1;

      const requestBody = {
        username: username,  
        emailid: email,   
        password: "",  // You can modify the password if required
        user_type: usertype, 
        Status: newStatus
      };
      
      await updateUser(userId, requestBody);
      setUserStatus((prevStatus) => ({
        ...prevStatus,
        [userId]: newStatus,
      }));
    } catch (error) {
      setError('Failed to update user status');
    }
  };

  if (loading) {
    return <Spinner animation="border" role="status"><span className="sr-only"></span></Spinner>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="d-flex flex-wrap justify-content-center">
      {users.map(user => (
        <Card 
          key={user.user_id} 
          className="m-3 shadow-sm" 
          style={{ width: '18rem', borderRadius: '10px' }}
        >
          <Card.Header 
            className="text-white" 
            style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
          >
            <h5 className="mb-0">{user.username}</h5>
          </Card.Header>
          <Card.Body>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <strong>Email:</strong> {user.emailid}
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>User role:</strong> {user.user_type}
              </ListGroup.Item>
              <ListGroup.Item>
              {(currentUserRole === 'Super-Admin') ? (
                  <Form.Check 
                    type="switch" 
                    id={`status-switch-${user.user_id}`} 
                    label={userStatus[user.user_id] ? 'Active' : 'Inactive'}
                    checked={userStatus[user.user_id]}
                    onChange={() => handleStatusChange(user.user_id, user.username, user.emailid, user.user_type)}
                  />
                ) : (
                  <span><strong>Status:</strong> {userStatus[user.user_id] ? 'Active' : 'Inactive'}</span>
                )}
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

export default UserDetails;
