import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Row, Col, ListGroup, Table } from "react-bootstrap";
import Toastify from "toastify-js";
import {
  createInvoice,
  updateInvoice,
  getInvoice,
  deleteInvoice,
  getBillingEntries,
  createBillingEntry,
  updateBillingEntry,
  deleteBillingEntry,
} from "../services/api-service";
import trash from "../assets/trash.svg";
import ConfirmDeleteModal from "./ConfirmDeleteModal";

const BillingDetails = ({ task }) => {
  const [billingDetails, setBillingDetails] = useState({
    appealFiling: "",
    writtenSubmission: "",
    repliesToNotices: "",
    miscellaneousExpenses: "",
    previousPayments: [],
  });
  const [previousPayment, setPreviousPayment] = useState({
    amount: "",
    date: "",
    proofs: [],
    description: "",
  });
  const [payments, setPayments] = useState([]);
  const [invoiceCreated, setInvoiceCreated] = useState(false);
  const [previousPaymentCreated, setpreviousPaymentCreated] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isPaymentEditing, setIsPaymentEditing] = useState(false);
  const [updateClickedOnce, setUpdateClickedOnce] = useState(false);
  const [updatePaymentClickedOnce, setUpdatePaymentClickedOnce] =
    useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isBillingOpen, setIsBillingOpen] = useState(false);
  const [isPaymentSaved, setIsPaymentSaved] = useState(false);
  const [selectedCaseId, setSelectedCaseId] = useState(null); 

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const response = await getInvoice(task.case_id);
        const invoice = response.data;
        setBillingDetails({
          appealFiling: invoice.appeal_filing || "",
          writtenSubmission: invoice.preparation_of_written_submission || "",
          repliesToNotices: invoice.replies_to_notices || "",
          miscellaneousExpenses: invoice.miscellaneous_expenses || "",
          previousPayments: invoice.previousPayments || [],
        });

        setInvoiceCreated(true);
      } catch (error) {
        console.error("Error fetching invoice:", error);
      }
    };

    fetchInvoice();
    fetchPreviousPayments();
  }, []);

  const fetchPreviousPayments = async () => {
    try {
      const response = await getBillingEntries(task.case_id);
      const payments = response.data;
      if (!Array.isArray(payments)) {
        throw new Error("Unexpected response format");
      }
      setPayments(payments);
      console.log(payments, "payments")
      setpreviousPaymentCreated(true);
    } catch (error) {
      console.error("Error fetching invoice:", error);
    }
  };

  const handleBillingDetailChange = (e) => {
    const { name, value } = e.target;
    setBillingDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handlePaymentDetailChange = (e) => {
    const { name, value } = e.target;
    setPreviousPayment((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    setPreviousPayment((prevPayment) => ({
      ...prevPayment,
      proofs: Array.from(files),
    }));
  };

  const calculateTotalAmount = () => {
    return (
      parseFloat(billingDetails.appealFiling || 0) +
      parseFloat(billingDetails.writtenSubmission || 0) +
      parseFloat(billingDetails.repliesToNotices || 0) +
      parseFloat(billingDetails.miscellaneousExpenses || 0)
    ).toFixed(2);
  };

  const calculateBalance = () => {
    const totalAmount = parseFloat(calculateTotalAmount() || 0);
    const previousPaymentsTotal = billingDetails.previousPayments.reduce(
      (sum, payment) => sum + parseFloat(payment.amount || 0),
      0
    );
    return (totalAmount - previousPaymentsTotal).toFixed(2);
  };

  const handleUpdateClick = () => {
    if (updateClickedOnce) {
      handleSubmit();
    } else {
      setIsEditing(true);
      setUpdateClickedOnce(true);
    }
  };

  const handleSubmit = async () => {
    const invoiceData = {
      case_id: task.case_id,
      appeal_filing: billingDetails.appealFiling || "",
      preparation_of_written_submission: billingDetails.writtenSubmission || "",
      replies_to_notices: billingDetails.repliesToNotices || "",
      miscellaneous_expenses: billingDetails.miscellaneousExpenses || "",
      total_amount: calculateTotalAmount(),
      edit_date: new Date().toISOString(),
      approval_status: "Pending",
      approval_date: "",
    };

    try {
      if (!invoiceCreated) {
        const response = await createInvoice(invoiceData);
        console.log("Invoice created successfully:", response);
        setInvoiceCreated(true);
        setIsEditing(false);
        setUpdateClickedOnce(false); // Reset after creation
        Toastify({
          text: "Invoice created successfully.",
          duration: 1000,
          close: true,
          gravity: "top",
          position: "right",
          backgroundColor: "linear-gradient(to right, #32CD32, #008000)",
          stopOnFocus: true,
        }).showToast();
      } else {
        // Updating existing invoice
        const response = await updateInvoice(task.case_id, invoiceData);
        console.log("Invoice updated successfully:", response);
        setIsEditing(false);
        setUpdateClickedOnce(false); // Reset after update
        Toastify({
          text: "Invoice updated successfully.",
          duration: 1000,
          close: true,
          gravity: "top",
          position: "right",
          backgroundColor: "linear-gradient(to right, #32CD32, #008000)",
          stopOnFocus: true,
        }).showToast();
      }
    } catch (error) {
      console.error("Error processing invoice:", error);
    }
  };

  const handlePreviousPaymentSubmit = async () => {
    const formData = new FormData();

    // Append files to FormData
    const paymentFiles = previousPayment.proofs;
    if (paymentFiles && paymentFiles.length > 0) {
      paymentFiles.forEach((file, index) => {
        formData.append("proofs", file); // Append file to FormData
      });
    } else {
      console.error("No proofs provided.");
      return;
    }
    const token = localStorage.getItem("access_token");
    // Append query parameters
    const url = new URL("http://18.139.227.59/billing/create");
    url.searchParams.append("case_id", task.case_id);
    url.searchParams.append("date", previousPayment.date);
    url.searchParams.append("description", previousPayment.description);
    url.searchParams.append("amount", previousPayment.amount);
    url.searchParams.append("token", token); // Replace with actual token

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error:", errorData);
      } else {
        const data = await response.json();
        console.log("Success:", data);
        // Handle success
        setpreviousPaymentCreated(true);
        setIsPaymentEditing(false);
        setUpdatePaymentClickedOnce(false);
        Toastify({
          text: "Billing created successfully.",
          duration: 1000,
          close: true,
          gravity: "top",
          position: "right",
          backgroundColor: "linear-gradient(to right, #32CD32, #008000)",
          stopOnFocus: true,
        }).showToast();
        fetchPreviousPayments();
      }
    } catch (error) {
      console.error("Error processing invoice:", error);
    }
  };

  const handleButtonClick = () => {
    if (invoiceCreated && !isEditing) {
      // Transition to edit mode
      setIsEditing(true);
    } else if (invoiceCreated && isEditing) {
      // Update invoice
      handleUpdateClick();
    } else {
      // Create new invoice
      handleSubmit();
    }
  };

  const getButtonContent = () => {
    if (invoiceCreated && isEditing) return "Update Invoice";
    if (invoiceCreated) return "Edit Invoice";
    return "Create Invoice";
  };

  const getButtonColor = () => {
    const content = getButtonContent();
    if (content === "Update Invoice") return "green"; // Use green for "Update Invoice"
    if (content === "Edit Invoice") return "var(--secondary-color)"; // Use CSS variable for "Edit Invoice"
    return "var(--secondary-color)";
  };

  const deleteInvoices = async () => {
    try {
      await deleteInvoice(task.case_id);
      setInvoiceCreated(false);
      setBillingDetails({
        appealFiling: "",
        writtenSubmission: "",
        repliesToNotices: "",
        miscellaneousExpenses: "",
        previousPayments: [],
      });
      setShowDeleteModal(false);
      Toastify({
        text: "Invoice deleted successfully.",
        duration: 1000,
        close: true,
        gravity: "top",
        position: "right",
        backgroundColor: "linear-gradient(to right, #32CD32, #008000)",
        stopOnFocus: true,
      }).showToast();
    } catch (error) {
      console.error("Failed to delete the invoice:", error.message);
    }
  };

  const handleDeleteBilling = async () => {
    try {
      await deleteBillingEntry(selectedCaseId); 
      setShowDeleteModal(false);
      setSelectedCaseId(null); 
      fetchPreviousPayments();
    } catch (error) {
      console.error("Error deleting billing entry:", error);
    }
  };

  const promptDelete = (caseId) => {
    setSelectedCaseId(caseId); // Store the case ID to delete
    setShowDeleteModal(true);  // Show the confirmation modal
  };  

  return (
    <div className="billing-details mt-2 small-font">
      <div className="d-flex justify-content-between align-items-center mt-1 mb-1">
        <h5 style={{ fontSize: "1.1rem" }}>Billing Details</h5>
        {/* <div
          onClick={handleDeleteInvoice}
          className="d-flex justify-content-end"
          style={{ cursor: "pointer" }}
        >
          <img src={trash} className="w-25" alt="trash-icon" />
        </div> */}
      </div>
      <Row>
        <Col md={4}>
          <Form.Group controlId="formAppealFiling">
            <Form.Label className="mt-1 mb-1">Appeal Filing</Form.Label>
            <Form.Control
              type="number"
              name="appealFiling"
              value={billingDetails.appealFiling}
              placeholder="INR"
              onChange={handleBillingDetailChange}
              className="small-font"
              disabled={invoiceCreated && !isEditing}
            />
          </Form.Group>
        </Col>

        <Col md={4}>
          <Form.Group controlId="formWrittenSubmission">
            <Form.Label className="mt-1 mb-1">Written Submission</Form.Label>
            <Form.Control
              type="number"
              name="writtenSubmission"
              value={billingDetails.writtenSubmission}
              placeholder="INR"
              onChange={handleBillingDetailChange}
              className="small-font"
              disabled={invoiceCreated && !isEditing}
            />
          </Form.Group>
        </Col>

        <Col md={4}>
          <Form.Group controlId="formRepliesToNotices">
            <Form.Label className="mt-1 mb-1">Replies to Notices</Form.Label>
            <Form.Control
              type="number"
              name="repliesToNotices"
              value={billingDetails.repliesToNotices}
              placeholder="INR"
              onChange={handleBillingDetailChange}
              className="small-font"
              disabled={invoiceCreated && !isEditing}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <Form.Group controlId="formMiscellaneousExpenses">
            <Form.Label className="mt-1 mb-1">
              Miscellaneous Expenses
            </Form.Label>
            <Form.Control
              type="number"
              name="miscellaneousExpenses"
              value={billingDetails.miscellaneousExpenses}
              placeholder="INR"
              onChange={handleBillingDetailChange}
              className="small-font"
              disabled={invoiceCreated && !isEditing}
            />
          </Form.Group>
        </Col>

        <Col md={4}>
          <Form.Group controlId="formTotalAmount">
            <Form.Label className="mt-1 mb-1">Total Amount</Form.Label>
            <Form.Control
              type="number"
              name="totalAmount"
              value={calculateTotalAmount()}
              placeholder="INR"
              readOnly
              className="small-font"
              disabled={invoiceCreated && !isEditing}
            />
          </Form.Group>
        </Col>

        <Col md={4}>
          <Form.Group controlId="formBalance">
            <Form.Label className="mt-1 mb-1">Balance Payment</Form.Label>
            <Form.Control
              type="number"
              name="balance"
              value={calculateBalance()}
              placeholder="INR"
              readOnly
              className="small-font"
              disabled={invoiceCreated && !isEditing}
            />
          </Form.Group>
        </Col>
      </Row>

      <Button
        variant="primary"
        style={{
          backgroundColor: getButtonColor(),
        }}
        className="small-font mt-2"
        onClick={handleButtonClick}
      >
        {getButtonContent()}
      </Button>

      <h5 className="mt-2 mb-0 small-font mb-2">Previous Payments</h5>
      <div>
        {payments.length > 0 ? (
          <Table bordered hover className="mb-3">
            <thead>
              <tr>
                <th>Amount</th>
                <th>Date</th>
                <th>Description</th>
                <th>Proof of Payment</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {payments.map((payment, index) => (
                <tr key={index}>
                  <td>{payment.amount}</td>
                  <td>{payment.date}</td>
                  <td>{payment.description}</td>
                  <td>{payment.proof_paths.join(", ")}</td>

                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      className="me-2"
                      onClick={() => promptDelete(payment._id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p className="small-font">No billings uploaded yet.</p>
        )}
      </div>
      <Row>
        <Col md={3}>
          <Form.Group controlId={`formPreviousPaymentAmount`}>
            <Form.Label className="mt-1 mb-1">Payment Amount</Form.Label>
            <Form.Control
              type="number"
              name="amount"
              value={previousPayment.amount}
              placeholder="INR"
              onChange={handlePaymentDetailChange}
              className="small-font"
              // disabled={invoiceCreated && !isEditing}
            />
          </Form.Group>
        </Col>

        <Col md={3}>
          <Form.Group controlId={`formPreviousPaymentDate`}>
            <Form.Label className="mt-1 mb-1">Payment Date</Form.Label>
            <Form.Control
              type="date"
              name="date"
              value={previousPayment.date}
              onChange={handlePaymentDetailChange}
              className="small-font"
              // disabled={invoiceCreated && !isEditing}
            />
          </Form.Group>
        </Col>

        <Col md={3}>
          <Form.Group controlId={`formPreviousPaymentProofs`}>
            <Form.Label className="mt-1 mb-1">Proof of Payment</Form.Label>
            <Form.Control
              type="file"
              name="proofs"
              onChange={handleFileChange}
              className="small-font"
              // disabled={invoiceCreated && !isEditing}
            />
          </Form.Group>
        </Col>

        <Col md={3}>
          <Form.Group controlId={`formPreviousPaymentAmount`}>
            <Form.Label className="mt-1 mb-1">Description</Form.Label>
            <Form.Control
              type="text"
              name="description"
              value={previousPayment.description}
              placeholder="Description"
              onChange={handlePaymentDetailChange}
              className="small-font"
              // disabled={invoiceCreated && !isEditing}
            />
          </Form.Group>
        </Col>
      </Row>

      <Button
        variant="secondary"
        onClick={handlePreviousPaymentSubmit}
        style={{
          backgroundColor: "var(--secondary-color)",
          marginRight: "0.5rem",
        }}
        className="small-font mt-2"
      >
        {/* {previousPaymentCreated ? 'Add Previous Payment' : 'Create Payment'} */}
        Add Previous Payment
      </Button>

      <ConfirmDeleteModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleDelete={handleDeleteBilling}
      />
    </div>
  );
};

export default BillingDetails;
