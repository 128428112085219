import React, { useState, useEffect } from 'react';
import KanbanBoard from './KanbanBoard';
import CreateCases from './CreateCases';
import { Modal, Button, Dropdown } from 'react-bootstrap';
import dashboard from '../assets/dashboard.svg';
import user from '../assets/user.svg';
import caseImage from '../assets/case.svg';
import dashboardActive from '../assets/dashboard-active.svg';
import userActive from '../assets/user-active.svg';
import caseActive from '../assets/case-active.svg';
import profile from '../assets/profile-svgrepo-com.svg';
import UserDetails from './userDetails';
import logo from '../assets/VisumAI_Dark_Transparent.png';
import { useNavigate } from 'react-router-dom';
import { logout, deleteCase, getCasesByUserId, getUserDetails } from '../services/api-service';
import Toastify from 'toastify-js';

const HomePage = () => {
  const [activeTab, setActiveTab] = useState('dashboard');
  const [showModal, setShowModal] = useState(false);
  const [tasks, setTasks] = useState([]);

  const handleShowModal = async () => {
    try {
      const userResponse = await getUserDetails();
  
      const userRole = userResponse.user_type;
  
      // Check if the user has the correct role to view the modal
      if (userRole !== 'Admin' && userRole !== 'Advocate') {
        Toastify({
          text: "Unauthorized: You don't have permission to perform this action.",
          duration: 3000,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          backgroundColor: "#f44336",
          stopOnFocus: true, // Prevents dismissing of toast on hover
        }).showToast();
        return;
      }
      setShowModal(true)
  
    } catch (error) {
      console.error('An error occurred:', error.message);
    }
  };

  const handleCloseModal = () => setShowModal(false);

  const navigate = useNavigate();

  const fetchTasks = async () => {
    try {
      const response = await getCasesByUserId(navigate);
      setTasks(response.data);
    } catch (error) {
      if (error.response && error.response.data.detail === "Token has expired") {
        console.error('Session expired, logging out...');
        localStorage.removeItem("access_token");
        Toastify({
          text: "Your session has expired. Please log in again.",
          duration: 3000,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          backgroundColor: "#f44336",
          stopOnFocus: true, // Prevents dismissing of toast on hover
        }).showToast();
        navigate("/"); // Redirect to login page
      } else {
        console.error('An error occurred:', error.message);
      }
    }
  };

  useEffect(() => {
    fetchTasks();
  }, [navigate]);

  const deleteTask = async (caseId) => {
    try {
      await deleteCase(caseId); // Ensure deleteCase is awaited
      fetchTasks(); // Fetch updated tasks
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  }  

  const addTask = (newTask) => {
    setTasks(prevTasks => [...prevTasks, newTask]);
  };


  const signOut = async () => {
    try {
      await logout(navigate); 
      navigate('/'); 
    } catch (error) {
      console.error('Sign-out failed:', error.message);
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'dashboard':
        return <KanbanBoard tasks={tasks} setTasks={setTasks} deleteTask={deleteTask} />;
      case 'cases':
        return <div>Content</div>;
      case 'users':
        return <UserDetails />;
      default:
        return null;
    }
  };

  return (
    <div style={{ backgroundColor: "var(--primary-bg)" }}>
      <div className="d-flex vh-100">
        <div
          className="bg-light border-right overflow-hidden"
          style={{ width: "15%", borderRight: "1px solid #ddd" }}
          id="sidebar-wrapper"
        >
          <div
            className="list-group list-group-flush"
            style={{ paddingRight: "2rem" }}
          >
            <img src={logo} alt="logo" />
            <a
              href="#"
              className={`list-group-item list-group-item-action d-flex align-items-center border-none border-0 ${
                activeTab === "dashboard" ? "active" : ""
              }`}
              onClick={() => setActiveTab("dashboard")}
            >
              <img
                // src={activeTab === 'dashboard' ? dashboardActive : dashboard}
                src={dashboard}
                alt="dashboard"
                className='img-md'
                style={{
                  width: "13%",
                  marginRight: "0.7rem",
                  marginLeft: "0.7rem",
                }}
              />
              Dashboard
            </a>
            {/* <a
              href="#"
              className={`list-group-item list-group-item-action mt-1 d-flex align-items-center border-none border-0 ${activeTab === 'cases' ? 'active' : ''}`}
              onClick={() => setActiveTab('cases')}
            >
              <img src={activeTab === 'cases' ? caseActive : caseImage} alt="cases" style={{ width: '13%', marginRight: '0.5rem', marginLeft: '0.7rem' }} />
              Cases
            </a> */}
            <a
              href="#"
              className={`list-group-item list-group-item-action mt-1 d-flex align-items-center border-none border-0 ${
                activeTab === "users" ? "active" : ""
              }`}
              onClick={() => setActiveTab("users")}
            >
              <img
                // src={activeTab === "users" ? userActive : user}
                src={user}
                alt="user"
                className='img-md'
                style={{
                  width: "12%",
                  marginRight: "0.7rem",
                  marginLeft: "0.7rem",
                }}
              />
              Users
            </a>
          </div>
        </div>
        <div style={{ width: "85%" }}>
          <nav className="navbar navbar-expand-lg navbar-light bg p-2" style={{ borderBottom: "1px solid #ddd" }}>
            <div className="container-fluid d-flex justify-content-between align-items-center">
              {/* Left Section: Create Case Button */}
              <Button
                variant="primary"
                style={{ backgroundColor: "var(--secondary-color)" }}
                onClick={handleShowModal}
              >
                Create Case
              </Button>

              {/* Right Section: User Profile Dropdown */}
              <Dropdown className="circle">
                <Dropdown.Toggle
                  as="div"
                  style={{ border: "none", background: "none" }}
                >
                  <img
                    src={profile}
                    alt="profile"
                    style={{ width: "1.5rem", cursor: "pointer" }}
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  <Dropdown.Item href="#/change-password">
                    Change Password
                  </Dropdown.Item>
                  <Dropdown.Item onClick={signOut}>Sign Out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </nav>


          <div className="container-fluid p-3 m-0 bg-light">
            {renderContent()}
            {/* {showModal && (
              <CreateCases
                addTask={addTask}
                closeModal={handleCloseModal}
                fetchTasks={fetchTasks} // Pass fetchTasks to refresh data after submission
              />
            )} */}
          </div>
        </div>
      </div>
      <Modal
        className="custom-modal-width"
        show={showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Case Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateCases
            addTask={addTask}
            closeModal={handleCloseModal}
            fetchTasks={fetchTasks}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HomePage;
